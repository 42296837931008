<template>
  <div>
    <h2 class="mt-3">Jobs by Market</h2>

    <div v-if="marketMap && currentView === 'list'">
      <div class="d-flex align-items-center font-xbold mt-3 mb-1 px-3">
        <div class="w-80">Market</div>
        <div class="w-80 text-center">
          <div class="font-light">Stationary</div>
          <div class="d-flex">
            <div class="w-40">Completed</div>
            <div class="w-40">Failed</div>
          </div>
        </div>

        <div class="w-80 text-center">
          <div class="font-light">Transit</div>
          <div class="d-flex">
            <div class="w-40">Completed</div>
            <div class="w-40">Failed</div>
          </div>
        </div>
      </div>

      <div class="pipeline-jobs-container table-container p-3">
        <div v-for="marketName in marketHashList" class="d-flex border-bottom mb-2">
          <div class="w-80 pointer" @click="onMarketClick(marketName)">{{ marketName }}</div>
          <div class="w-40 text-center">{{ stationaryCompletedJobs(marketMap[marketName]).length }}</div>
          <div class="w-40 text-center">{{ stationaryFailedJobs(marketMap[marketName]).length }}</div>
          <div class="w-40 text-center">{{ transitCompletedJobs(marketMap[marketName]).length }}</div>
          <div class="w-40 text-center">{{ transitFailedJobs(marketMap[marketName]).length }}</div>
        </div>
      </div>
    </div>

    <div v-if="marketMap && currentView === 'market'">
      <button class="my-1 pointer btn bg-orange-500 text-white" @click="currentView = 'list'">Back to Markets</button>
      <pipeline-jobs-table v-if="jobList.length > 0" :jobs="jobList" :markets-hash-table="marketsHashTable" >
      </pipeline-jobs-table>
    </div>

  </div>
</template>

<script>
import MarketUtil from "@/utils/MarketUtil";
import PipelineJobsTable from "@/views/pipeline/PipelineJobsTable.vue";

export default {
  name: 'JobsByMarket',
  components: {PipelineJobsTable},
  props: ['jobs', 'marketsHashTable'],
  data() {
    return {
      model: streetmetrics.model,
      marketMap: null,
      currentView: 'list',
      jobList: []
    }
  },
  computed: {
    marketHashList() {
      return Object.keys(this.marketMap).sort()
    }
  },
  mounted() {
    const jobsByMarketHashMap = streetmetrics.utils.array.groupBy(this.jobs, "marketHash")
    const marketHashList = Object.keys(jobsByMarketHashMap)
    const newMarketNameMap = {}
    const marketUtil = new MarketUtil();
    const marketHashtable = marketUtil.getMarketHashTable();

    for (let i = 0; i < marketHashList.length; i++) {
      const marketHash = marketHashList[i];
      const market = marketHashtable[marketHash];
      newMarketNameMap[market.marketName] = jobsByMarketHashMap[marketHash]
    }

    this.marketMap = newMarketNameMap;
  },
  methods: {
    stationaryJobs(jobList) {
      return jobList.filter((job) => { return job.jobType.toLowerCase() === 'stationary' })
    },
    stationaryCompletedJobs(jobList) {
      return this.stationaryJobs(jobList).filter((job) => { return job.status === 'COMPLETED' })
    },
    stationaryFailedJobs(jobList) {
      return this.stationaryJobs(jobList).filter((job) => { return job.status === 'FAILED' })
    },
    transitJobs(jobList) {
      return jobList.filter((job) => { return job.jobType.toLowerCase() === 'transit' })
    },
    transitCompletedJobs(jobList) {
      return this.transitJobs(jobList).filter((job) => { return job.status === 'COMPLETED' })
    },
    transitFailedJobs(jobList) {
      return this.transitJobs(jobList).filter((job) => { return job.status === 'FAILED' })
    },
    onMarketClick(marketName) {
      this.jobList = this.marketMap[marketName]
      this.currentView = 'market'
    }
  }
}
</script>
