import RestService from "./RestService";
import http from "superagent";
import StringUtil from "../utils/StringUtil";

export default class CustomerService extends RestService {

  constructor() {
    super('customers')
  }

  getSavedReports() {
    let url = this.baseUrl + '/v3/api/saved_reports/index'
    return this.executeGet(url)
  }

  campaignOverview(customerId) {
    let url = this.baseUrl + '/v3/internal/campaigns/campaign-overview/' + customerId
    return this.executeGet(url)
  }

  getVehicles(customerId) {
    let url = this.baseUrl + '/v3/internal/manager/customer-assets/' + customerId
    // let url = this.baseUrl + '/v3/api/assets/admin-list/' + customerId
    return this.executeGet(url)
  }

  getVehicleHeathCheck(assetId, providerId) {
    let url = this.baseUrl + '/v3/internal/manager/customer-assets/' + customerId
    return this.executeGet(url)
  }

  getProviders(customerId) {
    let url = this.baseUrl + '/v3/api/providers/customer/' + customerId
    return this.executeGet(url)
  }

  list() {
    let url = this.baseUrl + '/v3/api/' + this.modelPath + '?t=' + Date.now()

    return new Promise((resolve, reject) => {
      http.get(url)
        .set('Accept', 'application/json')
        .set('Authorization', 'Bearer ' + this.authToken)
        .end((error, response) => {

          if (response.status === 200) {
            const customerResponse = JSON.parse(response.text)
            let stringUtil = new StringUtil()
            stringUtil.sortByString(customerResponse.data, 'customerName')

            for( let i = 0; i < customerResponse.data.length; i++ ) {
              let customer = customerResponse.data[i]
              customer.slug  = stringUtil.toUrlFriendlyId(customer.customerName)
            }

            resolve(customerResponse)
          } else {
            reject(JSON.parse(response.text))
          }
        })
    });
  }

}
