import RestService from "./RestService";

export default class DevService  extends RestService {

  constructor() {
    super('dev')
  }

  testConfig() {
    let url = this.baseUrl + '/v3/api/dev'
    return this.executeGet(url)
  }

  getPipelineDebugFiles() {
    let url = this.baseUrl + '/v3/api/dev/pipeline-debug'
    return this.executeGet(url)
  }

  testAttribution() {
    let url = this.baseUrl + '/v3/api/dev/attribution-test'
    return this.executeGet(url)
  }

  testEmail() {
    let url = this.baseUrl + '/v3/api/dev/email-test'
    return this.executeGet(url)
  }

  testAssetHealthClick() {
    let assetId = 'ATL001 G-WASHINGTON'
    let providerName = 'LEDMediaLinxup'
    let url = this.baseUrl + `/v3/api/assets/health-check`
    return this.post(url, {assetId: assetId, providerName: providerName})
  }

  testInStoreProcessing() {
    let url = this.baseUrl + '/v3/api/dev/test-in-store-processing'
    return this.executeGet(url)
  }

  testMapPath2() {
    let url = this.baseUrl + '/v3/api/dev/map-test'
    return this.executeGet(url)
  }

  testMapPath() {

    /*

    {
    "name": "CPD (22311357)  - 8/7-12/6",
    "marketIds": [
        "97"
    ],
    "campaignIds": [
        "26511"
    ],
    "start": "2023-08-08T04:00:00.000Z",
    "end": "2023-12-07T04:59:59.999Z",
    "email": [
        "maakeda.sinclair@intersection.com"
    ]
}

     */

    ///{"name":"Lansing Sept 2023","marketIds":["55"],"campaignIds":["18613"],"start":"2023-09-01T05:00:00.000Z","end":"2023-10-01T04:59:59.999Z","email":["harris@streetmetrics.com"]}
    // "marketIds" := Array[55], "campaignIds" := Array[18613], "mainRange" := Array[ 1693544400, 1696136400 ]);
    let startDate = '2023-08-08T04:00:00.000Z'
    let endDate = '2023-12-07T04:59:59.999Z'
    let marketIds = 97
    let campaignIds = 26511
    let url = this.baseUrl + `/v3/internal/map/historical/census?marketIds=${marketIds}&campaignIds=${campaignIds}&start=${startDate}&end=${endDate}`
    return this.executeGet(url)
  }

  testReach() {
    let assetGroup = 765
    let assetOwner = 'Miami-Dade Transit'
    let url = this.baseUrl + `/v3/internal/planner/impressions?assetGroupId=${assetGroup}&assetOwner=${assetOwner}`
    return this.executeGet(url)
  }

  testCreatingNearJob() {
    let url = this.baseUrl + '/v3/api/dev/test-near-api'
    return this.executeGet(url)
  }

  testNearJobStatus() {
    let url = this.baseUrl + '/v3/api/dev/get-near-job-status'
    return this.executeGet(url)
  }

  testRedisConnection() {
    let url = this.baseUrl + '/v3/api/dev/redis'
    return this.executeGet(url)
  }

}
