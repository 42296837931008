<template>
  <div class="p-4">
    <h2>Developers!</h2>

    <div v-if="redisConfig.redisHost" class="my-2 alert alert-warning" role="alert">
      <div><strong>Redis Port:</strong> {{this.redisConfig.redisPort}}</div>
      <div><strong>Redis Host:</strong> {{this.redisConfig.redisHost}}</div>
    </div>

    <div class="d-flex my-2 flex-wrap">
      <button class="btn btn-primary my-1" @click="onTestRedisClick">Test Redis Connection</button>
      <button class="btn btn-primary ms-2 my-1" @click="onTestAttributionClick">Test Attribution</button>
      <button class="btn btn-primary ms-2 my-1" @click="onTestCreateNearJobClick">Test Near Create Job</button>
      <button class="btn btn-primary ms-2 my-1" @click="onTestCreateNearJobStatusClick">Test Near Job Status</button>
      <button class="btn btn-primary ms-2 my-1" @click="onTestInStoreProcessingClick">Test In Store Processing</button>
      <button class="btn btn-primary ms-2 my-1" @click="onTestAssetHealthClick">Test Asset Health</button>
      <button class="btn btn-primary ms-2 my-1" @click="onTestEmailClick">Test Email</button>
      <button class="btn btn-primary ms-2 my-1" @click="onTestMapDataClick">Test Map API</button>
    </div>

    <div>
      <button class="btn btn-primary" @click="showDefaultModal = true">Open Default Modal</button>
    </div>

    <default-modal v-if="showDefaultModal" @noEvent="showDefaultModal = false"
                   :yesEvent="onOkModalClick"></default-modal>
  </div>
</template>

<script>
import DevService from "../services/DevService";
import DeviceService from "../services/DeviceService";
import RestService from "@/services/RestService";
import CampaignService from "@/services/CampaignService";
import DefaultModal from "@/components/DefaultModal.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";

export default {
  name: 'Dev',
  components: {ConfirmModal, DefaultModal},
  props: ['prop1', 'prop2'],
  data () {
    return {
      model: streetmetrics.model,
      service: new DevService(),
      redisConfig: {
        redisPort: '',
        redisHost: '',
      },
      showDefaultModal: false
    }
  },
  mounted () {
    this.service.testConfig().then( (response) => {
      this.redisConfig.redisPort = response.data.REDIS_PORT
      this.redisConfig.redisHost = response.data.REDIS_HOST
    })

    // this.onTestReachQuery()
    this.testPackageByMarket()
    this.loadActiveMarkets()
  },
  methods: {
    onOkModalClick() {
      alert('Great job, you clicked OK!')
    },
    loadActiveMarkets() {
      let campaignService = new CampaignService()
      campaignService.getActiveCampaigns();
    },

    testPackageByMarket() {
      let url = 'http://localhost:3000/v3/api/package/by-market/2'
      let restService = new RestService('package')
      restService.executeGet(url)
    },

    onTestRedisClick() {
      this.service.testRedisConnection().then( (response) => {

        if(response.status === 'success') {
          this.model.toast.background = 'bg-green'
          this.model.toast.message = "Redis connection successful."
        }
        else {
          this.model.toast.background = 'bg-orange'
          this.model.toast.message = "Redis connection failed."
        }
      })
    },
    onTestAttributionClick() {
      this.service.testAttribution()
    },

    onTestMapDataClick() {
      //this.service.testMapPath()
      this.service.testMapPath2()
    },

    onTestEmailClick() {
      this.service.testEmail()
    },

    onTestCreateNearJobClick() {
      this.service.testCreatingNearJob()
    },
    onTestCreateNearJobStatusClick() {
      this.service.testNearJobStatus()
    },
    onTestInStoreProcessingClick() {
      this.service.testInStoreProcessing()
    },
    onTestReachQuery() {
      this.service.testReach()
    },
    onTestAssetHealthClick() {
      this.service.testAssetHealthClick().then( (response) => {
        console.log('Asset health response')
        console.log(response)
      })
    }
  }
}
</script>

<style>

</style>
