import RestService from './RestService';

export default class PixelService extends RestService {
  constructor() {
    super('pixels');
  }

  getPixels(customerId) {
    let url = this.baseUrl + '/v3/api/pixels/customer/' + customerId;
    return this.executeGet(url);
  }

  getPixelCount(campaignId) {
    let url = this.baseUrl + '/v3/api/pixels/pixel-count/' + campaignId;
    return this.executeGet(url);
  }

  getCampaignPixels(campaignId) {
    let url = this.baseUrl + '/v3/api/pixels/campaign/' + campaignId;
    return this.executeGet(url);
  }

  getPixelsMissingCampaign() {
    let url = this.baseUrl + '/v3/api/pixels/missing-campaign';
    return this.executeGet(url);
  }

  checkPixelCount(pixelId) {
    let url = this.baseUrl + '/v3/api/pixels/pixel-check/' + pixelId;
    return this.executeGet(url);
  }

  getPixelData(pixelId) {
    let url = this.baseUrl + '/v3/api/pixels/pixelData/' + pixelId;
    return this.executeGet(url);
  }

  loadPixelRequests(pixelId, startDate, endDate) {
    let queryString = `?pixelId=${pixelId}&startDate=${startDate}&endDate=${endDate}`;
    let url = this.baseUrl + '/v3/api/pixels/pixel-query' + queryString;
    return this.executeGet(url);
  }

  createPixelJob(pixelId, startDate, endDate, customerId, campaignId) {
    let queryString = `?pixelId=${pixelId}&startDate=${startDate}&endDate=${endDate}&customerId=${customerId}&campaignId=${campaignId}`;
    let url = this.baseUrl + '/v3/api/pixels/pixel-query-async' + queryString;
    return this.executeGet(url);
  }

  getPixelsByAttributionId(attributionId, customerId) {
    let url =
      this.baseUrl +
      `/v3/api/pixels/attribution/${attributionId}/customer/${customerId}`;
    return this.executeGet(url);
  }
}
