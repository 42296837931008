<template>
  <div id="pixelFormModal" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">

          <div class="d-flex align-items-center p-3">
            <div class="centered-circle flex-shrink-0 bg-secondary text-primary h-10 w-10">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="feather feather-code small-icon">
                <polyline points="16 18 22 12 16 6"></polyline>
                <polyline points="8 6 2 12 8 18"></polyline>
              </svg>
            </div>
            <div class="ms-3">
              <h3 class="fs-5 spacing-close">{{ actionLabel }} Pixel</h3>
            </div>
          </div>

          <div v-if="errorMessage" class="alert alert-warning mt-1 mb-3" role="alert">
            {{ errorMessage }}
          </div>

          <form>
            <div class="mb-3">
              <label for="pixelNameInput" class="form-label">Display Name</label>
              <input v-model="pixel.displayName" type="text" class="form-control" id="pixelNameInput">
            </div>

            <div class="mb-3">
              <label class="form-label">Funnel Stage</label>
              <select class="form-select" v-model="pixel.funnelStage">
                <option v-for="funnelStage in funnelStages">{{ funnelStage }}
                </option>
              </select>
            </div>

            <div class="mb-3">
              <label class="form-label">Page</label>
              <select class="form-select" v-model="pixel.page">
                <option v-for="pageType in pageTypes">{{ pageType }}
                </option>
              </select>
            </div>

            <div class="mb-3">
              <label for="urlInput" class="form-label">Url</label>
              <input v-model="pixel.url" type="text" class="form-control" id="urlInput">
            </div>

            <div v-if="campaignEditable" class="mb-3">
              <label class="form-label">Customer</label>
              <select class="form-select" v-model="customerId">
                <option v-for="customer in model.customers" :value="customer.customerId">{{ customer.customerName }}
                </option>
              </select>
            </div>

            <div v-if="customerId" class="mb-3">
              <label class="form-label">Campaign</label>
              <select class="form-select" v-model="pixel.campaign">
                <option v-for="campaign in availableCampaigns" :value="campaign.campaignId">{{ campaign.campaignRef }}
                </option>
              </select>
            </div>

            <div class="mb-3">
              <label for="pixelUUIDInput" class="form-label">UUID</label>
              <input v-model="pixel.pixelUUID" type="text" class="form-control" id="pixelUUIDInput" disabled>
            </div>
          </form>

        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary font-sm" style="min-width: 150px;" @click="onSaveClick">Save
          </button>
          <button type="button" class="btn btn-outline-primary font-sm" data-bs-dismiss="modal"
                  style="min-width: 150px;">Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PixelService from "../services/PixelService";

export default {
  name: 'pixelFormModal',
  props: {
    yesEvent: Function,
    pixel: Object,
    customer: Object
  },
  data() {
    return {
      model: streetmetrics.model,
      currentModal: null,
      modalElement: null,
      service: new PixelService(),
      errorMessage: null,
      customerId: null,
      campaignEditable: this.pixel.campaign ? false : true,
      pageTypes: ['Home', 'Product', 'Checkout', 'Landing', 'Location', 'Contact Us', 'Education', 'Directory'],
      funnelStages: ['Visit', 'Consideration', 'Conversion']
    }
  },
  computed: {
    actionLabel() {
      return this.pixel.pixelId ? 'Edit' : 'New'
    },
    availableCampaigns() {
      if (this.customerId) {
        return this.model.campaigns.filter(campaign => (campaign.customerId === this.customerId))
      }

      return []
    },
  },
  mounted() {
    
    if(this.customer) {
      this.customerId = this.customer.customerId
    }

    this.modalElement = document.getElementById('pixelFormModal')
    this.currentModal = new bootstrap.Modal(this.modalElement)

    this.modalElement.addEventListener('hidden.bs.modal', () => {
      this.$emit('noEvent')
    })

    this.currentModal.show()
  },
  beforeDestroy() {
    this.modalElement.removeEventListener('hidden.bs.modal')
  },
  methods: {
    onSaveClick() {
      this.errorMessage = null

      if(!this.pixel.displayName && !this.pixel.displayName.trim()) {
        this.errorMessage = 'A name is required.'
        return
      }

      if(!this.pixel.page && !this.pixel.page.trim()) {
        this.errorMessage = 'A page is required.'
        return
      }

      let request = this.pixel
      let id = this.pixel.pixelId
      this.model.loading = true
      let promise = id ? this.service.update(this.pixel.pixelUUID, request) : this.service.create(request)

      promise.then((response) => {
        this.model.loading = false
        id = this.pixel.pixelId ? this.pixel.pixelId : response.data
        this.yesHandler(id)
        this.model.toast.message = 'Pixel saved'
        console.log('Pixel saved')
        console.log(response)
      }).catch((error) => {
        console.log('error saving pixel')
        console.log(error)
        this.errorMessage = error.message
        this.model.loading = false
      })
    },

    noHandler(e) {
      // hide the modal
      this.currentModal.hide()
    },
    yesHandler(pixelId) {
      // call the parent's "yesEvent"
      if (this.yesEvent) {
        this.yesEvent(pixelId)
      }
      // hide the modal
      this.noHandler()
    }
  }
}
</script>

<style>

</style>
