import RestService from "./RestService";
import AppUtil from "@/utils/AppUtil";

export default class PipelineService extends RestService {

    constructor() {
        super('pipeline')
        this.baseUrl = new AppUtil().getPipelineUrl();
    }

    testPipelineApiCall() {
        let url = this.baseUrl + '/api/hello'
        return this.executeGet(url)
    }

    loadJobs() {
        let url = this.baseUrl + '/api/jobs'
        return this.executeGet(url)
    }

    loadFrames() {
        let url = this.baseUrl + '/frames/frames-by-market'
        return this.executeGet(url)
    }
}