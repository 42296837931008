<template>
  <div>
    <h2 class="my-3">Jobs by Date</h2>
    <div v-if="currentView === 'calendar'" class="pipeline-jobs-container table-container p-3">
      <FullCalendar ref="jobsCalendar"  :options="calendarOptions"/>
    </div>

    <div v-if="currentView === 'dateList'">
      <button class="my-1 pointer btn bg-orange-500 text-white" @click="onBackClick()">Back to Calendar</button>
      <pipeline-jobs-table v-if="jobList.length > 0" :jobs="jobList" :markets-hash-table="marketsHashTable" >
      </pipeline-jobs-table>
    </div>

  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import dayjs from "dayjs";
import PipelineJobsTable from "@/views/pipeline/PipelineJobsTable.vue";

export default {
  name: 'JobsByDate',
  components: {
    PipelineJobsTable,
    FullCalendar
  },
  props: ['jobs', 'marketsHashTable'],
  data() {
    return {
      model: streetmetrics.model,
      currentView: 'calendar',
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        eventClick: this.onDateClick,
        events: []
      },
      calendarApi: null,
      jobList: [],
      jobsByDateHashMap: {}
    }
  },
  mounted() {
    this.jobsByDateHashMap = streetmetrics.utils.array.groupBy(this.jobs, "date")
    const datesList = Object.keys(this.jobsByDateHashMap)
    const events = []

    for (let i = 0; i < datesList.length; i++) {
      const dateString = datesList[i];
      const jobList = this.jobsByDateHashMap[dateString]
      const hasFailedJobs = this.failedJobs(jobList).length > 0
      const metadata = {date: dateString, jobs: jobList}
      const backgroundColor = hasFailedJobs ? '#fca5a5' : '#bbf7d0'
      const borderColor = backgroundColor
      const textColor = hasFailedJobs ? '#b91c1c' : '#15803d'
      events.push({ title: 'Job Count: ' + jobList.length, date: dateString, backgroundColor: backgroundColor,
        borderColor: borderColor, textColor: textColor, extendedProps: metadata })
    }

    this.calendarOptions.events = events

    if(dayjs().date() < 8) {
      this.calendarApi = this.$refs.jobsCalendar.getApi()
      this.calendarApi.prev()
    }
  },
  methods: {
    onDateClick(eventObject) {
      this.jobList = this.jobsByDateHashMap[eventObject.event.extendedProps.date]
      this.currentView = 'dateList'
    },
    onBackClick() {
      this.currentView = 'calendar'

      // give the calendar a half sec to render
      setTimeout( () => {
        if(dayjs().date() < 8) {
          console.log('Calling prev')
          this.calendarApi = this.$refs.jobsCalendar.getApi()
          this.calendarApi.prev()
        }
      }, 500)
    },
    failedJobs(jobList) {
      return jobList.filter((job) => {
        return job.status === 'FAILED'
      })
    }
  }
}
</script>
