import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/Login';
import MainContainer from '../layout/MainContainer';
import Dashboard from '../views/Dashboard';
import CustomerList from '../views/CustomerList';
import DeviceExport from '../views/DeviceExport';
import Profile from '../views/Profile';
import CustomerDetails from '../views/CustomerDetails';
import Markets from '../views/Markets';
import Pixels from '../views/Pixels';
import Dev from '../views/Dev';
import AttributionJobs from '../views/AttributionJobs';
import Attribution from '../views/Attribution';
import AttributionReport from '../views/AttributionReport';
import Multipliers from '../views/Multipliers';
import SelectAttributionLocations from '@/components/SelectAttributionLocations';
import Providers from '@/views/Providers';
import AthenaJobs from '@/views/AthenaJobs';
import AssetOwners from '@/views/AssetOwners';
import EditAttribution from "@/views/EditAttribution";
import Pipeline from "@/views/pipeline/Pipeline.vue";
import PipelineJobForm from "@/views/pipeline/PipelineJobForm.vue";
import MediaUpload from "@/views/MediaUpload.vue";
import Stationary from "@/views/pipeline/Stationary.vue";
import RunCampaignForm from "@/views/pipeline/RunCampaignForm.vue";
import FramesList from "@/views/inventory/FramesList.vue";
import FramesByMarket from "@/views/pipeline/FramesByMarket.vue";

const routes = [
  {
    path: '',
    redirect: '/app/dashboard',
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: Login,
  },
  {
    path: '/app',
    component: MainContainer,
    redirect: '/app/dashboard',
    children: [
      {
        path: '/app/dashboard',
        name: 'dashboard',
        component: Dashboard,
      },
      {
        path: '/app/customers',
        name: 'customers',
        component: CustomerList,
      },
      {
        path: '/app/markets',
        name: 'markets',
        component: Markets,
      },
      {
        path: '/app/customer/:customerUrlFriendlyId',
        name: 'customer-details',
        component: CustomerDetails,
        props: true,
      },
      {
        path: '/app/upload',
        name: 'media-upload',
        component: MediaUpload,
      },
      {
        path: '/app/devices',
        name: 'devices',
        component: DeviceExport,
      },
      {
        path: '/app/pixels',
        name: 'pixels',
        component: Pixels,
      },
      {
        path: '/app/providers',
        name: 'providers',
        component: Providers,
      },
      {
        path: '/app/inventory/frames',
        name: 'inventory-frames',
        component: FramesList
      },
      // {
      //   path: '/app/new-place',
      //   name: 'new-place',
      //   component: PlaceForm,
      // },
      // {
      //   path: '/app/edit-place/:id',
      //   name: 'edit-place',
      //   component: PlaceForm,
      // },
      // {
      //   path: '/app/places',
      //   name: 'places',
      //   component: Places,
      // },
      // {
      //   path: '/app/place-details/:id',
      //   name: 'place-details',
      //   component: PlaceDetails,
      // },
      // {
      //   path: '/app/jobs',
      //   name: 'jobs',
      //   component: Jobs,
      // },
      {
        path: '/app/pipeline',
        name: 'pipeline',
        component: Pipeline,
      },
      {
        path: '/app/new-pipeline-job',
        name: 'new-pipeline-job',
        component: PipelineJobForm,
      },
      {
        path: '/app/stationary-pipeline',
        name: 'stationary-pipeline',
        component: Stationary,
      },
      {
        path: '/app/run-campaign-pipeline/:id',
        name: 'run-campaign',
        component: RunCampaignForm,
      },
      {
        path: '/app/pipeline-frames-by-market/:id',
        name: 'frames-by-market',
        component: FramesByMarket,
      },
      {
        path: '/app/processing-jobs',
        name: 'processing-jobs',
        component: AthenaJobs,
      },
      {
        path: '/app/attribution-jobs',
        name: 'attribution-jobs',
        component: AttributionJobs,
      },
      {
        path: '/app/attribution',
        name: 'attribution',
        component: Attribution,
      },
      {
        path: '/app/edit-attribution-study',
        name: 'edit-attribution-study',
        component: EditAttribution,
      },
      {
        path: '/app/select-locations/:id',
        name: 'select-attribution-locations',
        component: SelectAttributionLocations,
      },
      {
        path: '/app/attribution-report/:id',
        name: 'attribution-report',
        component: AttributionReport,
      },
      {
        path: '/app/dev',
        name: 'dev',
        component: Dev,
      },
      {
        path: '/app/profile',
        name: 'profile',
        component: Profile,
      },
      {
        path: '/app/multipliers',
        name: 'multipliers',
        component: Multipliers,
      },
      {
        path: '/app/asset-owners',
        name: 'asset-owners',
        component: AssetOwners,
      },
    ],
  },
];

function checkAuthentication() {
  if (window.localStorage.getItem('auth.token')) {
    streetmetrics.model.authToken = window.localStorage.getItem('auth.token');
    return true;
  }

  return false;
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === 'sign-in') {
    next();
  } else if (!checkAuthentication()) {
    next({ name: 'sign-in' });
  } else {
    next();
  }
});

export default router;
