<template>
  <div
    id="sidebarMenu"
    class="p-3 d-none d-md-flex flex-column min-vh-100 flex-shrink-0 sidebar collapse"
  >
    <ul
      class="nav nav-pills flex-column mb-auto pt-5 rounded-4 h-100-pct bg-primary font-sm"
    >
      <li class="nav-item" :class="getColorClass('dashboard')">
        <router-link
          :to="{ name: 'dashboard' }"
          class="nav-link d-flex align-items-centert text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            class="small-icon"
            fill="currentColor"
          >
            <g data-name="Layer 2">
              <g data-name="grid">
                <rect width="24" height="24" opacity="0"></rect>
                <path
                  d="M9 3H5a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zM5 9V5h4v4z"
                ></path>
                <path
                  d="M19 3h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zm-4 6V5h4v4z"
                ></path>
                <path
                  d="M9 13H5a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2zm-4 6v-4h4v4z"
                ></path>
                <path
                  d="M19 13h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2zm-4 6v-4h4v4z"
                ></path>
              </g>
            </g>
          </svg>
          <span class="ms-2">Dashboard</span>
        </router-link>
      </li>

      <li class="nav-item" :class="getColorClass('customer')">
        <router-link
          :to="{ name: 'customers' }"
          class="nav-link d-flex align-items-center text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            class="eva eva-award-outline small-icon"
            fill="currentColor"
          >
            <g data-name="Layer 2">
              <g data-name="award">
                <rect width="24" height="24" opacity="0"></rect>
                <path
                  d="M19 20.75l-2.31-9A5.94 5.94 0 0 0 18 8 6 6 0 0 0 6 8a5.94 5.94 0 0 0 1.34 3.77L5 20.75a1 1 0 0 0 1.48 1.11l5.33-3.13 5.68 3.14A.91.91 0 0 0 18 22a1 1 0 0 0 1-1.25zM12 4a4 4 0 1 1-4 4 4 4 0 0 1 4-4zm.31 12.71a1 1 0 0 0-1 0l-3.75 2.2L9 13.21a5.94 5.94 0 0 0 5.92 0L16.45 19z"
                ></path>
              </g>
            </g>
          </svg>
          <span class="ms-2">Customers</span>
        </router-link>
      </li>

      <li class="nav-item" :class="getColorClass('markets')">
        <router-link
          :to="{ name: 'markets' }"
          class="nav-link d-flex align-items-center text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-map small-icon"
          >
            <polygon
              points="1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6"
            ></polygon>
            <line x1="8" y1="2" x2="8" y2="18"></line>
            <line x1="16" y1="6" x2="16" y2="22"></line>
          </svg>
          <span class="ms-2">Markets</span>
        </router-link>
      </li>

      <li class="nav-item" :class="getColorClass('devices')">
        <router-link
          :to="{ name: 'devices' }"
          class="nav-link d-flex align-items-center text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-smartphone small-icon"
          >
            <rect x="5" y="2" width="14" height="20" rx="2" ry="2"></rect>
            <line x1="12" y1="18" x2="12.01" y2="18"></line>
          </svg>
          <span class="ms-2">Device Export</span>
        </router-link>
      </li>

      <li class="nav-item" :class="getColorClass('media-upload')">
        <router-link
          :to="{ name: 'media-upload' }"
          class="nav-link d-flex align-items-center text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-upload small-icon"
          >
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
            <polyline points="17 8 12 3 7 8"></polyline>
            <line x1="12" y1="3" x2="12" y2="15"></line>
          </svg>
          <span class="ms-2">Upload</span>
        </router-link>
      </li>

      <li class="nav-item" :class="getColorClass('pixels')">
        <router-link
          :to="{ name: 'pixels' }"
          class="nav-link d-flex align-items-center text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-code small-icon"
          >
            <polyline points="16 18 22 12 16 6"></polyline>
            <polyline points="8 6 2 12 8 18"></polyline>
          </svg>
          <span class="ms-2">Pixels</span>
        </router-link>
      </li>

      <li class="nav-item" :class="getColorClass('pipeline')">
        <div>
          <router-link
            :to="{ name: 'pipeline' }"
            class="nav-link d-flex align-items-center text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="small-icon"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m6.75 7.5 3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0 0 21 18V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v12a2.25 2.25 0 0 0 2.25 2.25Z"
              />
            </svg>
            <span class="ms-2">Pipeline</span>
          </router-link>
        </div>
      </li>

      <li class="ms-4 me-3 nav-item" v-if="isActive('pipeline')">
        <div class="rounded-3" :class="isStationaryPipelinePath ? 'bg-gray-100 theme-color' : 'sub-nav-link'">
          <router-link
            :to="{ name: 'stationary-pipeline' }"
            class="d-flex align-items-center" :class="isStationaryPipelinePath ? 'nav-link' : 'py-1 px-3'"
          >
            <span class="ms-2">Stationary</span>
          </router-link>
        </div>
      </li>

      <li class="nav-item" :class="getColorClass('processing-jobs')">
        <router-link
          :to="{ name: 'processing-jobs' }"
          class="nav-link d-flex align-items-center text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-refresh-cw small-icon"
          >
            <polyline points="23 4 23 10 17 10"></polyline>
            <polyline points="1 20 1 14 7 14"></polyline>
            <path
              d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"
            ></path>
          </svg>

          <span class="ms-2">Athena Jobs</span>
        </router-link>
      </li>

      <li class="nav-item" :class="getColorClass('attribution')">
        <router-link
          :to="{ name: 'attribution' }"
          class="nav-link d-flex align-items-center text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-target small-icon"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <circle cx="12" cy="12" r="6"></circle>
            <circle cx="12" cy="12" r="2"></circle>
          </svg>

          <span class="ms-2">Attribution</span>
        </router-link>
      </li>

      <li class="nav-item" :class="getColorClass('multipliers')">
        <router-link
          :to="{ name: 'multipliers' }"
          class="nav-link d-flex align-items-center text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-crosshair"
          >
            <circle cx="12" cy="12" r="10" />
            <line x1="22" y1="12" x2="18" y2="12" />
            <line x1="6" y1="12" x2="2" y2="12" />
            <line x1="12" y1="6" x2="12" y2="2" />
            <line x1="12" y1="22" x2="12" y2="18" />
          </svg>

          <span class="ms-2">Multipliers</span>
        </router-link>
      </li>

      <li class="nav-item" :class="getDevColorClass('providers')">
        <router-link
          :to="{ name: 'providers' }"
          class="nav-link d-flex align-items-center text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-download"
          >
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
            <polyline points="7 10 12 15 17 10"></polyline>
            <line x1="12" y1="15" x2="12" y2="3"></line>
          </svg>
          <span class="ms-2">Providers</span>
        </router-link>
      </li>

      <li class="nav-item" :class="getDevColorClass('asset-owners')">
        <router-link
          :to="{ name: 'asset-owners' }"
          class="nav-link d-flex align-items-center text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-triangle small-icon"
          >
            <path
              d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"
            ></path>
          </svg>
          <span class="ms-2">Asset Owners</span>
        </router-link>
      </li>

      <li class="nav-item" :class="getDevColorClass('asset-owners')">
        <router-link
          :to="{ name: 'inventory-frames' }"
          class="nav-link d-flex align-items-center text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-truck small-icon"
          >
            <rect x="1" y="3" width="15" height="13"></rect>
            <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
            <circle cx="5.5" cy="18.5" r="2.5"></circle>
            <circle cx="18.5" cy="18.5" r="2.5"></circle>
          </svg>
          <span class="ms-2">Inventory</span>
        </router-link>
      </li>

      <li
        v-if="isDeveloper()"
        class="nav-item"
        :class="getDevColorClass('dev')"
      >
        <router-link
          :to="{ name: 'dev' }"
          class="nav-link d-flex align-items-center text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-terminal small-icon"
          >
            <polyline points="4 17 10 11 4 5"></polyline>
            <line x1="12" y1="19" x2="20" y2="19"></line>
          </svg>
          <span class="ms-2">Developers</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      model: streetmetrics.model,
    };
  },
  computed: {
    isStationaryPipelinePath() {
      return this.$route.name === 'stationary-pipeline'
    }
  },
  methods: {
    isDeveloper() {
      return this.model.isDeveloper();
    },
    getColorClass(linkLabel) {
      return this.$route.name.indexOf(linkLabel) > -1
        ? "border-start border-5 border-secondary"
        : "";
    },
    isActive(linkLabel) {
      return this.$route.name.indexOf(linkLabel) > -1;
    },
    getDevColorClass(linkLabel) {
      return this.$route.name === linkLabel
        ? "border-start border-5 border-secondary"
        : "";
    },
  },
};
</script>
