export default class ArrayUtil {

  find(list, itemId, fieldName) {
    for( let i = 0; i < list.length; i++ ) {
      const item = list[i]
      if(item[fieldName] === itemId) {
        return item
      }
    }
  }

  groupBy(list, fieldName) {
    const map = {}

    for( let i = 0; i < list.length; i++ ) {
      const item = list[i]
      const fieldValue = item[fieldName]
      if(!map[fieldValue]) {
        map[fieldValue] = []
      }

      map[fieldValue].push(item)
    }

    return map
  }

  updateItem(list, item, fieldName) {
    for( let i = 0; i < list.length; i++ ) {
      if(item[fieldName] === list[i][fieldName]) {
        list[i] = item
        break
      }
    }
  }
}
