import {
  faIdBadge,
  faUser,
  faBullseye,
  faLayerGroup,
  faCalendarDays,
  faTowerCell,
  faSignal,
  faCalendarPlus,
  faHandshake,
  faCirclePause,
  faCircleCheck,
  faArrowsToEye,
  faRectangleAd,
  faPen,
  faFolderOpen,
  faSquarePlus,
  faClock,
  faThumbsUp,
  faArrowsSpin,
  faMagnifyingGlass,
  faCheck,
  faBookmark,
  faPlus,
  faMinus,
} from '@fortawesome/free-solid-svg-icons';

export default [
  faIdBadge,
  faUser,
  faBullseye,
  faLayerGroup,
  faCalendarDays,
  faTowerCell,
  faSignal,
  faCalendarPlus,
  faHandshake,
  faCirclePause,
  faCircleCheck,
  faArrowsToEye,
  faRectangleAd,
  faPen,
  faFolderOpen,
  faSquarePlus,
  faClock,
  faThumbsUp,
  faArrowsSpin,
  faMagnifyingGlass,
  faCheck,
  faBookmark,
  faPlus,
  faMinus,
];
