import http from 'superagent';
import AppUtil from '../utils/AppUtil';

export default class RestService {
  constructor(modelPath) {
    this.modelPath = modelPath;
    // eslint-disable-next-line no-undef
    this.authToken = streetmetrics.model.authToken;
    this.http = http;
    this.baseUrl = new AppUtil().getBaseUrl();
  }

  list() {
    let url = this.baseUrl + '/v3/api/' + this.modelPath + '?t=' + Date.now();
    return this.executeGet(url);
  }

  post(url, body) {
    return new Promise((resolve, reject) => {
      this.http
        .post(url)
        .send(body)
        .set('Authorization', 'Bearer ' + this.authToken)
        .set('Accept', 'application/json')
        .end((error, response) => {
          if (response && (response.status === 200 || response.status === 201)) {
            resolve(JSON.parse(response.text));
          } else {
            reject(error || JSON.parse(response.text));
          }
        });
    });
  }

  put(url, body) {
    return new Promise((resolve, reject) => {
      this.http
        .put(url)
        .send(body)
        .set('Authorization', 'Bearer ' + this.authToken)
        .set('Accept', 'application/json')
        .end((error, response) => {
          if (response && (response.status === 200 || response.status === 201)) {
            resolve(JSON.parse(response.text));
          } else {
            reject(error || JSON.parse(response.text));
          }
        });
    });
  }

  delete(id, overrideUrl) {
    let url = overrideUrl ? overrideUrl : this.baseUrl + '/v3/api/' + this.modelPath;

    return new Promise((resolve, reject) => {
      http
        .delete(url)
        .set('Authorization', 'Bearer ' + this.authToken)
        .set('Accept', 'application/json')
        .end((error, response) => {
          if (response.status === 200) {
            resolve(JSON.parse(response.text));
          } else {
            reject(JSON.parse(response.text));
          }
        });
    });
  }

  create(model, overrideUrl) {
    let url = overrideUrl ? overrideUrl : this.baseUrl + '/v3/api/' + this.modelPath;
    return new Promise((resolve, reject) => {
      http
        .post(url)
        .send(model)
        .set('Authorization', 'Bearer ' + this.authToken)
        .set('Accept', 'application/json')
        .end((error, response) => {
          if (response.status === 200 || response.status === 201) {
            resolve(JSON.parse(response.text));
          } else {
            reject(JSON.parse(response.text));
          }
        });
    });
  }

  update(id, model, overrideUrl) {
    let url = overrideUrl
      ? overrideUrl
      : this.baseUrl + '/v3/api/' + this.modelPath + '/' + id + '?t=' + Date.now();
    return new Promise((resolve, reject) => {
      http
        .put(url)
        .send(model)
        .set('Authorization', 'Bearer ' + this.authToken)
        .set('Accept', 'application/json')
        .end((error, response) => {
          if (response.status === 200) {
            resolve(JSON.parse(response.text));
          } else {
            reject(JSON.parse(response.text));
          }
        });
    });
  }

  get(id) {
    let url = this.baseUrl + '/v3/api/' + this.modelPath + '/' + id + '?t=' + Date.now();
    return this.executeGet(url);
  }

  executeGet(url) {
    return new Promise((resolve, reject) => {
      http
        .get(url)
        .set('Accept', 'application/json')
        .set('Authorization', 'Bearer ' + this.authToken)
        .end((error, response) => {
          if (response && response.status === 200) {
            const data = JSON.parse(response.text);
            resolve(data);
          } else if (response && response.status === 403) {
            streetmetrics.model.loading = false;
            streetmetrics.router.push({ name: 'sign-in' });
          } else {
            if (response) {
              reject(JSON.parse(response.text));
            } else {
              reject(error);
            }
          }
        });
    });
  }
}
