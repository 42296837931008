<template>
  <div class="pt-4 px-4">

    <div class="mb-3 d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <h2>StreetMetrics Pipeline</h2>

        <div>
          <button @click="loadJobs()" class="btn btn-sm btn-primary ms-2">
            Refresh
          </button>
        </div>
      </div>

      <div>
        <button v-if="isDeveloper()" @click="showRunDayModal = true" class="btn btn-primary me-2">
          Run Day
        </button>

        <router-link :to="{ name: 'new-pipeline-job' }" class="btn btn-secondary">
          New Pipeline Job
        </router-link>
      </div>
    </div>

    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a href="javascript:void(0)" class="nav-link" :class="currentTab === 'dashboard' ? 'active' : ''"
           @click="currentTab = 'dashboard'">Dashboard</a>
      </li>
      <li class="nav-item">
        <a href="javascript:void(0)" class="nav-link" :class="currentTab === 'jobsByDate' ? 'active' : ''"
           @click="currentTab = 'jobsByDate'">Job by Date</a>
      </li>
      <li class="nav-item">
        <a href="javascript:void(0)" class="nav-link" :class="currentTab === 'jobsByMarket' ? 'active' : ''"
           @click="currentTab = 'jobsByMarket'">Job by Market</a>
      </li>
      <li class="nav-item">
        <a href="javascript:void(0)" class="nav-link" :class="currentTab === 'jobs' ? 'active' : ''"
           @click="currentTab = 'jobs'">Job List</a>
      </li>
      <li class="nav-item">
        <a href="javascript:void(0)" class="nav-link" :class="currentTab === 'explorer' ? 'active' : ''"
           @click="currentTab = 'explorer'">Explore</a>
      </li>
    </ul>

    <job-dashboard v-if="currentTab === 'dashboard'" :jobs="jobs" :markets-hash-table="marketsHashTable"></job-dashboard>
    <jobs-by-date v-else-if="currentTab === 'jobsByDate'" :jobs="jobs" :markets-hash-table="marketsHashTable" ></jobs-by-date>
    <jobs-by-market v-else-if="currentTab === 'jobsByMarket'" :jobs="jobs" :markets-hash-table="marketsHashTable" ></jobs-by-market>
    <pipeline-jobs-list v-else-if="currentTab === 'jobs'" :jobs="jobs" :markets-hash-table="marketsHashTable"></pipeline-jobs-list>
    <report-explorer v-else-if="currentTab === 'explorer'"></report-explorer>
    <run-day-modal v-if="showRunDayModal" @noEvent="showRunDayModal = false"
                   :yes-event="onRunDateConfirmed"></run-day-modal>
  </div>
</template>

<script>
import PipelineService from "@/services/PipelineService";
import MarketUtil from "@/utils/MarketUtil";
import dayjs from "dayjs";
import streetmetrics from "@/model";
import PipelineJobsList from "@/views/pipeline/PipelineJobsList.vue";
import JobDashboard from "@/views/pipeline/JobDashboard.vue";
import RunDayModal from "@/views/pipeline/RunDayModal.vue";
import CustomerFormModal from "@/components/CustomerFormModal.vue";
import AppUtil from "@/utils/AppUtil";
import JobsByMarket from "@/views/pipeline/JobsByMarket.vue";
import JobsByDate from "@/views/pipeline/JobsByDate.vue";
import ReportExplorer from "@/views/pipeline/ReportExplorer.vue";

export default {
  name: 'Pipeline',
  components: {ReportExplorer, JobsByDate, JobsByMarket, CustomerFormModal, RunDayModal, JobDashboard, PipelineJobsList},
  data() {
    return {
      model: streetmetrics.model,
      service: new PipelineService(),
      currentTab: 'dashboard',
      jobs: [],
      currentMarketHashes: [],
      allMarketsHashTable: null,
      marketsHashTable: null,
      showRunDayModal: false,
    }
  },
  mounted() {
    this.allMarketsHashTable = new MarketUtil().getMarketHashTable();
    this.loadJobs()
    streetmetrics.eventDispatcher.on('refreshJobsEvent', this.loadJobs)
  },
  beforeUnmount() {
    streetmetrics.eventDispatcher.off('refreshJobsEvent', this.loadJobs)
  },
  methods: {
    loadJobs() {
      this.model.loading = true
      this.service.loadJobs().then((response) => {
        this.jobs = response
        const marketTable = {}

        for (let i = 0; i < this.jobs.length; i++) {
          const job = this.jobs[i];
          marketTable[job.marketHash] = this.allMarketsHashTable[job.marketHash]
        }

        this.marketsHashTable = marketTable
        this.model.loading = false
      }).catch((error) => {
        this.model.loading = false
        this.model.toast.background = 'bg-red';
        this.model.toast.message =
            'There was an error trying load the jobs.';
      });
    },

    isDeveloper() {
      return this.model.isDeveloper();
    },

    onRunDateConfirmed(date) {
      const activeMarkets = this.model.markets.filter(market => (market.metaData && market.metaData.isActive))
      const promises = []

      for (let i = 0; i < activeMarkets.length; i++) {
        const activeMarket = activeMarkets[i];

        const request = {
          market: activeMarket.marketHash,
          marketId: activeMarket.marketId,
          startDate: date,
          runBy: this.model.user.displayName
        }

        const url = new AppUtil().getPipelineUrl() + '/api/run-market'
        const promise = this.service.post(url, request);
        promises.push(promise);
      }

      Promise.all(promises).then( (response) => {
        this.model.toast.background = 'bg-green';
        this.model.toast.message = 'Jobs sent';
        this.loadJobs()
      }).catch((error) => {
        this.model.toast.background = 'bg-red';
        this.model.toast.message =
            'There was an error trying to run this date. Please try again later.';
      });
    },
  }
}
</script>
