<template>
  <div class="pt-3 px-3">

    <h2 class="mb-2">Device Export</h2>

    <div class="card mb-3">
      <div class="card-body">

        <div class="row w-100-pct">
          <div class="mb-3 col-12 col-md-4">
            <label class="form-label">Customer</label>
            <select class="form-select" v-model="customerId">
              <option v-for="customer in model.customers" :value="customer.customerId">{{ customer.customerName }}
              </option>
            </select>
          </div>

          <div class="mb-3 col-12 col-md-4 px-2">
            <label class="form-label">Campaigns</label>
            <select-dropdown :list="availableCampaigns" label-field="campaignRef"
                             list-title="Campaigns"></select-dropdown>
          </div>

          <div class="mb-3 col-12 col-md-4 px-2">
            <label class="form-label">Date Filter</label>

            <div class="d-flex">
              <div class="me-2">
                <select class="form-select mb-2" v-model="dateFilter" @change="getFilterDates">
                  <option value="yesterday">Yesterday</option>
                  <option value="last7">Last 7 Days</option>
                  <option value="last30">Last 30 Days</option>
                  <option value="custom">Custom</option>
                </select>
                <div v-if="dateFilter !== 'custom' && startDate && endDate" class="form-text text-secondary font-xbold">
                  {{ startDate.toLocaleDateString("en-US") }} - {{ endDate.toLocaleDateString("en-US") }}
                </div>
              </div>

              <div v-if="dateFilter === 'custom'" class="w-100-pct">
                <Datepicker class="mb-2" v-model="dates" :range="true" :clearable="false"
                            :enableTimePicker="false" @closed="onDateClosed"
                            @blur="onDateSelected"></Datepicker>
                <div v-if="startDate && endDate" class="form-text text-secondary font-xbold">
                  {{ startDate.toLocaleDateString("en-US") }} - {{ endDate.toLocaleDateString("en-US") }}
                </div>
                <div v-if="dateFilter === 'custom' && (startDate === null || endDate === null)"
                     class="form-text text-danger font-xbold">
                  Please select a start and end date.
                </div>
              </div>
            </div>

          </div>

        </div>

        <div>
          <button class="btn btn-secondary btn-wide" @click="onGetDevicesClick" :disabled="isInvalidRequest">Get
            Devices
          </button>

        </div>

      </div>

    </div>

    <div v-if="totalCount > -1 && uniqueCount > -1" class="card mb-3">
      <div class="card-body">

        <div class="d-flex justify-content-between">
          <div class="card-text d-flex align-items-center">
            <div>
              <strong>Total: </strong> {{ totalCount.toLocaleString() }} | <strong>Unique: </strong> {{ uniqueCount.toLocaleString() }}
            </div>
          </div>

          <div v-if="devices.length > 0">
            <button class="btn btn-primary" @click="onDownloadClick">Download</button>
          </div>
        </div>

      </div>
    </div>

    <div v-if="errorMessage" class="alert alert-warning" role="alert">
      {{errorMessage}}
    </div>

  </div>

</template>

<script>
import DeviceService from "../services/DeviceService";
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css'
import SelectDropdown from "../components/SelectDropdown";
import AppUtil from "../utils/AppUtil";
import JobService from "../services/JobService";
import JobsTable from "../components/JobsTable";

export default {
  name: 'DeviceExport',
  components: {JobsTable, SelectDropdown, Datepicker},
  data() {
    return {
      // eslint-disable-next-line no-undef
      model: streetmetrics.model,
      startDate: null,
      endDate: null,
      customerId: null,
      dateFilter: null,
      dates: [],
      totalCount: -1,
      uniqueCount: -1,
      devices: [],
      selectableCampaigns: [],
      errorMessage: null
    }
  },
  computed: {
    isInvalidRequest() {
      //return this.customerId === null || this.selectedCampaigns.length === 0 || this.startDate === null || this.endDate === null
      return this.customerId === null || this.selectedCampaigns.length === 0
    },
    availableCampaigns() {
      if (this.customerId) {
        return this.selectableCampaigns.filter(campaign => (campaign.customerId === this.customerId))
      }

      return this.selectableCampaigns
    },
    selectedCampaigns() {
      return this.selectableCampaigns.filter(item => (item.selected))
    }
  },
  created() {
    this.selectableCampaigns = this.model.campaigns.map((item) => {
      item.selected = false
      return item
    })
  },
  mounted() {
  },
  methods: {
    onDownloadClick() {
      let csvContent = "data:text/csv;charset=utf-8,device_id\n" + this.devices.join("\n")
      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "export.csv")
      link.click();
    },
    onDateClosed() {
      this.onDateSelected()
    },
    onDateSelected() {
      this.startDate = this.dates[0]
      this.endDate = this.dates[1]
    },
    onGetDevicesClick() {
      this.errorMessage = null
      this.model.loading = true
      this.devices = []
      this.totalCount = 0
      this.uniqueCount = 0
      let service = new DeviceService()
      let filterDates = this.getFilterDates()
      let campaignIds = this.selectedCampaigns.map(campaign => campaign.campaignId)
      service.search(filterDates.start, filterDates.end, this.customerId, campaignIds, this.model.user.email).then((response) => {

        if(response.data.status && response.data.status === 'error') {
          this.errorMessage = response.data.message
        }
        else {
          this.totalCount = response.data.counts.total
          this.uniqueCount = response.data.counts.unique
          this.devices = response.data.raw
        }

        this.model.loading = false
      }).catch((error) => {
        console.log('Error loading devices')
        console.log(error)
        this.model.loading = false
      })
    },
    getFilterDates() {
      if (this.dateFilter === null) {
        return {start: null, end: null};
      }

      if (this.dateFilter !== 'custom') {
        const appUtil = new AppUtil()
        const startAndEndDates = appUtil.getFilterDates(this.dateFilter)
        this.startDate = startAndEndDates.start
        this.endDate = startAndEndDates.end
        this.dates = [startAndEndDates.start, startAndEndDates.end]
        let startFilter = startAndEndDates.start.getTime() / 1000
        let endFilter = startAndEndDates.end.getTime() / 1000
        return {start: Math.round(startFilter), end: Math.round(endFilter)};
      } else if (this.startDate && this.endDate) {
        let startFilter = this.startDate.getTime() / 1000
        let endFilter = this.endDate.getTime() / 1000
        return {start: Math.round(startFilter), end: Math.round(endFilter)};
      }
    },
  }
}
</script>

<style>

</style>
